import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

import ContactusImage from "../components/contactus/contactusImage"
import ContactusModule from "../components/contactus/contactusModule"


const ContactPage = () => (
    <Layout>
        <SEO title="Contact" />
        <Container className="pad_header">
            <h1 className="header_title">Let's Connect</h1>
        </Container>

        <ContactusImage />
        <ContactusModule />

    </Layout>
)

export default ContactPage