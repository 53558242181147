import React from 'react'
import { Image } from 'react-bootstrap'
import hero from "../../images/contact-hero.jpg"

const ContactusImage = () => {
  return (
    <Image src={hero} alt="about-hero" className="generalHerobanner"/>
  )
}

export default ContactusImage