import React from "react"
import { Button, ButtonGroup } from 'react-bootstrap'


const JusButton = props => {
  return (
    <ButtonGroup>
        <Button type="submit" className="lets_button_st lets_b_txt">{props.label}</Button>
        <Button type="submit" className="lets_button_st lets_b_icon">{props.icon}</Button>
    </ButtonGroup>
  )
}

export default JusButton