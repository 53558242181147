import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import JusButton from "../common/jus-button" 



const ContactusModule = props => {
    
return (
    <Container>
    <div className="jus_con_box_st">
        <Row className="margin_0">
            <Col
                className="background_white col-lg-8 pad_65"
            >
                <h4 className="main_title main_b_color">Send us a message</h4>
                <form
                    action="https://formspree.io/mvoadqng"
                    method="POST"
                    className="jus_contact_st"
                >
                    <input type="hidden" name="form-name" value="Contact Form" />
                    <Row className="margin_bot_35">
                        <Col className="col-sm-6">
                            <p className="main_text main_b_color">First Name*</p>
                            <input name="fname" type="text" required id="fname" />
                        </Col>
                        <Col className="col-sm-6">
                            <p className="main_text main_b_color">Last Name*</p>
                            <input name="lname" type="text" required id="lname" />
                        </Col>
                    </Row>
                    <div className="margin_bot_35">
                        <p className="main_text main_b_color">Company/Organization*</p>
                        <input name="company" type="text" required id="company" />
                    </div>
                    <Row className="margin_bot_35">
                        <Col className="col-sm-6">
                            <p className="main_text main_b_color">Email*</p>
                            <input name="email" type="email" required id="email" />
                        </Col>
                        <Col className="col-sm-6">
                            <p className="main_text main_b_color">Phone Number*</p>
                            <NumberFormat className="form-control" required style={{marginLeft:'5px'}}
                                format="(###) ###-####"
                                mask=""
                                />
                        </Col>
                    </Row>
                    <div className="margin_bot_35">
                        <p className="main_text main_b_color">Message*</p>
                        <textarea name="message" required className="min_h_150"/>
                    </div>
                    <JusButton label="Let's get Started" icon=">"/>
                </form>
            </Col>
            <Col
                className="background_mask_blue col-lg-4 pad_65"
            >
                <h4 className="txt_align_center main_title main_w_color margin_bot_35">Contact information</h4>
                <div className="contact_info">
                    <div className="margin_bot_35">
                        <p className="main_text main_w_color">285 Fulton Street, Suite 8500<br />New York City NY 10007</p>
                    </div>
                    <div className="margin_bot_35">
                        <p className="main_text main_w_color">Office: +1 212.220.6580<br />Fax: +1 212 220 8558</p>
                    </div>
                    <div className="margin_bot_35">
                        <p className="main_text main_w_color">info@jus-pharma.com</p>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
    
    </Container>
)
}

export default ContactusModule